.layout-fixed-header {

  .main-panel {

    > .header {
      padding-left: 0;
      z-index: 2000;

      .dropdown-menu {
        min-width: 100px;
        border: 1px solid rgba(white, 0.54);
        background: rgba(darken($black-2, 7.84), 0.92);
        text-align: right;

        a {
          color: white;
          font-size: 0.875rem;

          &:hover, &:focus {
            background: $green-1;
          }
        }
      }

      .brand .brand-logo {
        color: $green-1;
        text-transform: capitalize;

        img {
          margin-right: rem(10px);
        }
      }
    }

    .main-content {
      padding-top: $header-height;
    }
  }
}

.layout-small-menu {

  .main-panel>.main-content {
    padding-left: 0;
  }

  .sidebar-panel ul.nav > li {

    > a {
      text-align: center;

      i {
        margin-right: 0
      }
    }

    > ul.sub-menu {
      background: rgba($black-2, 0.8) url("../images/sidebar-bg.png") 0 0 repeat;

      > li > a {
        padding-left: rem(10px);
      }
    }
  }
}

.sidebar-panel {
  padding-top: ($header-height + 0.075rem);
  background: $black-1 url("../images/sidebar-bg.png") 0 0 repeat;
  padding-bottom: $footer-height;
  z-index: 1040;

  ul.nav {

    a, a:hover, a:focus {
      color: white !important;
      font-size: .875rem;
    }

    > li {
      border-bottom: 1px solid $black-2;

      > a {
        font-weight: bold;
        text-transform: uppercase;
        padding-left: rem(16px);

        span {
          display: inline;
        }

        i {
          color: $green-1;
          margin-right: rem(5px);
        }

        &:hover {
          background: $green-1 !important;

          i {
            color: white;
          }
        }
      }

      &.active, &.open {
        > a, > a:focus {
          background: rgba($green-1, 0.4);
        }
      }
    }
  }

  > nav li.open, > nav li:hover {

    &.menu-accordion > a:before {
      background: white;
      box-shadow: 0 0 0 0.0625rem white;
    }

    .sub-menu {
      background: rgba(white, 0.1);

      li {
        border-bottom: 1px solid $black-2;

        a {
          padding-left: rem(40px);

          span {
            display: inline;
          }

          i {
            color: $green-1;
            font-size: rem(14px);
            margin-right: rem(5px);
          }

          &:hover {
            background: $green-1 !important;

            i {
              color: white;
            }
          }
        }

        &.active a, .active a:focus {
          background: rgba($green-1, 0.6);
          font-weight: bold;
        }
      }
    }
  }
}

.main-panel {

  > .header {
    height: $header-height;
    background-color: $black-2;
    box-shadow: 0 1px 2px darken($black-2, 16.86);

    .hamburger-icon {
      padding: 0;

      span {
        background: $green-1;

        &:hover {
          background: white;
        }
      }
    }

    i {
      font-size: 1rem;
    }

    .nav > li {
      a {
        max-height: $header-height;
      }

      &, &.open {
        > a, > a:hover, > a:focus {
          background: transparent;
        }
      }
    }
  }

  > .main-content {
    padding: 0;

    .main-subcontent {
      padding: 0 1rem;
    }
  }
}

.brand {
  display: inline-block;

  .brand-logo {
    margin-top: 0.3rem;
  }
}

.breadcrumb {
  border-bottom: 1px solid $black-2;
  background: $black-1 url("../images/sidebar-bg.png") 0 0 repeat;
  padding: rem(10.5px) 1rem;
  color: lighten($black-1, 40.78);
  border-left: 1px solid darken($gray-1, 25.49);
  font-style: italic;

  > .active {
    color: lighten($black-1, 40.78);
  }

  > li:first-child:before {
    content: "\e021";
    font-family: "Glyphicons Halflings";
    font-style: normal;
    margin-right: 1rem;
    color: $green-1;
    font-size: rem(14px);
  }

  > li + li::before {
    content: "/";
  }

  li a:focus, li a:hover {
    color: lighten($black-1, 40.78);
  }
}

footer.content-footer {
  padding-left: 3.5rem;
  z-index: 2000;
  background: white url("../images/footer-bg.jpg") repeat-x;
  height: $footer-height;

  nav ul li a {
    line-height: 0.8rem;
    font-size: 0.6875rem;
  }
}

@media screen and (min-width: $screen-sm-min) {

  .layout-fixed-header.layout-small-menu .main-panel > .header {
    padding: 0;
  }

  .main-panel > .main-content {
    padding: $header-height 0 0 0.65rem;
  }

  .sidebar-panel {
    width: $sidebar-width;

    nav > ul {
      width: $sidebar-width;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .sidebar-panel {
    padding-bottom: 0;
    z-index: 1000;
  }

  .layout-fixed-header.layout-small-menu {
    .sidebar-panel ul.nav > li .sub-menu > li > a {
      text-align: center;
    }
  }

  .offscreen {
    &::before {
      z-index: 0;
    }
  }

  .main-panel > .header .hamburger-icon {
    padding: .9375rem;
  }
}

::-moz-selection {
  background: $green-1;
}

::selection {
  background: $green-1;
}

.m-b-xs {
  margin-bottom: 5px;
}

.no-padding {
  padding: 0 !important;
}

.text-danger {
  color: $brand-danger;
}

.b-a-0, .no-border {
  border: 0 !important;
}

.bg-default-gradient {
  background-image: linear-gradient(to bottom, lighten($gray-1, 1.96), darken($gray-1, 4.71));
}

.translate-cloak {
  display: none !important;
}
.loading[translate-cloak] {
  display: none !important;
}
.loading.translate-cloak[translate-cloak] {
  display: block !important;
}

.color-primary {
  color: $green-1
}
