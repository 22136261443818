@import "../../../vendors/bootstrap-sass/assets/stylesheets/bootstrap/variables";

$sidebar-width: 13.125rem;
$header-height: 3.125rem;
$footer-height: 2.188rem;
$brand-danger: #d9534f;

$black-1: #777;
$black-2: #525252;
$green-1: #00B0CA;
$gray-1: #ebebeb;
