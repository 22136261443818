.form {
  &.ng-invalid.ng-submitted {

    .input__field.ng-invalid {

      + .input__label {

        span {
          color: $brand-danger;
        }
        &:before, &:after {
          background: $brand-danger;
        }
      }
    }

    .select2-container-multi .select2-choices,
    .select2-choice.select2-default {
      border-color: $brand-danger;

      .select2-chosen {
        color: $brand-danger;
      }
    }

    .input__errors {
      color: $brand-danger;
    }
  }
}

.form-control:focus {
  border-color: $green-1;
}

.form-control.input-sm {
  height: 34px;
}

select.form-control {
  width: 100%;
}

.input__label {
  &::after {
    background: $green-1;
    transform: translate3d(101%, 0, 0);
  }
}

.input__field {
  padding: 18px 12px 6px 0;

  &.ng-valid-required, &.ng-invalid-required {

    + .input__label .input__label-content:after,
    .select2-choice.select2-default .select2-chosen {
      content: "*";
      margin-left: rem(10px);
      color: $brand-danger;
    }
  }
}

.select2-container.input__field {
  box-sizing: content-box;
  padding: 0;
}

.input__field:focus + .input__label .input__label-content,
.input--filled .input__label-content,
.input--focused .input__label-content {
  color: $green-1;
  transform: translateZ(0) scale3d(1, 1, 1);
}


// select2
.select2-search-choice-close,
.select2-search input {
  background-image: url("../images/select2x2.png") !important;
}

.select2-container {
  width: 100%;

  .select2-choice .select2-arrow b,
  .select2-choice abbr {
    background-image: url("../images/select2x2.png") !important;
  }

  .select2-choice abbr {
    right: 40px;
    top: 20px
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-results .select2-highlighted {
  background-color: $green-1;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices,
.select2-dropdown-open.select2-drop-above .select2-selection,
.select2-dropdown-open.select2-drop-above .select2-choices,
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container-multi.select2-container-active .select2-choices {
  border-color: $green-1;
}

.select2-drop-active {
  z-index: 10000;
  padding-top: 5px;
  border: none;
}

.select2-container .select2-choice,
.ui-select-container[theme=select2] .select2-choice {
  border: none;
  padding: 0 0 rem(20px) 0;
  background: transparent;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background-image: none;
  border: none;
}

.select2-container .select2-choice > .select2-chosen {
  color: $black-2
}

.select2-container-active.select2-dropdown-open {
  .select2-choice {
    border-width: 1px;
  }
}

.select2-choice:not(.select2-default) {
  padding: 8px 0;
  border-bottom: none;
}

.select2-container .select2-choice .select2-arrow,
.ui-select-container[theme=select2] .select2-choice .select2-arrow {
  background: transparent;
}

.select2-drop.select2-drop-above.select2-drop-active {
  border-top: none;
}

.select2-container .select2-choice .select2-arrow {
  top: 5px;
}

.select2-dropdown-open.select2-drop-above {
  background-color: white;
  border: 1px solid $green-1;
  margin-bottom: -2px;

  .select2-choice {
    border-radius: 0;
    margin-left: 1px;
  }
}

.select2-container-multi {
  .select2-choices {
    background: transparent;
    border: none;
    position: absolute;
    bottom: 0;
    width: 100%;

    .select2-search-field input {
      padding: 0.1rem .3125rem;
      width: 100%;
    }
  }

  &.select2-container-active .select2-choices {
    border: none;
  }

  &.select2-dropdown-open ~ .input__label {
    .input__label-content {
      color: $green-1;
    }
    &:after, &:before {
      background: $green-1;
    }
    .input__label-content {
      -webkit-transform: translate3d(0, 0, 0) scale3d(0.7, 0.7, 1);
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
  }

  &.input__field {
    height: 45px;
  }
}
