table.smart-table {
  border-radius: 5px 5px 0 0;
  background-color: transparent;
  margin-top: 20px;
  margin-bottom: 0;

  thead {
    border: solid rgba(darken(saturate($gray-1, 0.86), 37.65), 0.59);
    border-width: 1px 1px 0 1px;
    background-image: linear-gradient(to bottom, lighten($gray-1, 1.96), darken($gray-1, 4.71));

    th {
      font-weight: bold;
      text-transform: none;
      position: relative;

      &.st-sort-ascent:before,
      &.st-sort-descent:before,
      &:before {
        font-family: "FontAwesome";
        position: absolute;
        right: 0;
      }

      &.st-sort-ascent:before {
        content: "\f0de";
      }

      &.st-sort-descent:before {
        content: "\f0dd";
      }

      &[st-sort]:not(.st-sort-ascent):not(.st-sort-descent):before {
        content: "\f0dc";
        color: darken($gray-1, 5.49);
      }
    }

    tr:first-child th:first-child {
      border-top-left-radius: 5px;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 5px;
    }
  }

  tbody {
    border: solid rgba(darken(saturate($gray-1, 0.86), 37.65), 0.59);
    border-width: 0 1px 0 1px;

    tr {

      &:nth-child(2n) {
        background-color: white;
      }

      &:nth-child(odd) {
        background-color: lighten($gray-1, 3.14);
      }

      td {
        padding: .1rem 1.25rem;
        line-height: 2.5;

        &.td-detail {
          color: $green-1;

          a:hover, a:focus {
            text-decoration: underline;
            color: $green-1;
          }
        }

        &.td-actions {
          text-align: center;
        }
      }

      &:last-child td {
        border-bottom: 1px solid rgba(darken(saturate($gray-1, 0.86), 37.65), 0.59);
      }
    }
  }

  tfoot {

    td {
      padding: 0;
    }

    .pagination {
      vertical-align: middle;
    }
  }

  i {
    font-size: 20px;
    cursor: pointer;
  }
}

.table-filters {
  margin-bottom: 20px;

  label {
    font-weight: bold;
  }
}

.table-advanced-search {

  .card-header {
    padding: 7.5px 20px;
  }
}

.table-length {
  padding: 0;

  select {
    width: auto;

    &:focus {
      outline: none;
      border: none;
    }
  }
}

.table-search {
  display: flex;
  align-items: center;
  text-align: right;
  padding: 0;

  input {
    margin-left: 5px;
    border-radius: 4px;
    border: 1px solid darken($gray-1, 12.16);

    &:focus {
      border: 1px solid darken($gray-1, 12.16);
    }
  }
}

.table-info {
  margin: 20px 0;
}
