.btn-primary {
  @include button-variant(white, $green-1, $green-1);
}

.btn-primary-darken {
  @include button-variant(white, darken($green-1, 10%), darken($green-1, 10%));
}

.btn-language {
  .btn.dropdown-toggle {
    background-color: white !important;
    padding-left: rem(10px);
    padding-right: rem(10px);
    width: 100%;
    text-align: left;

    .caret {
      position: absolute;
      top: 40%;
      right: rem(10px);
    }
  }

  .dropdown-menu {
    min-width: 0;

    li a {
      padding-right: rem(42px);
      padding-left: rem(12px);

      &:hover {
        background-color: $green-1;
        color: white;
      }
    }
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: rem(15px);
  }
}

.btn-default-gradient {
  font-size: rem(14px);
  background: darken($gray-1, 7.45) -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, 0.6)),
    to(rgba(255, 255, 255, 0)));
  border-color: rgba(black, 0.3);
  color: $black-2;
  border-radius: 4px;
  padding: rem(5px) rem(10px);

  &:hover:not(.disabled) {
    background: darken($gray-1, 20.00) -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, 0.6)),
      to(rgba(255, 255, 255, 0)));
    color: $black-2;
  }
}
