.app.signin {
  background: url('../images/grey-bg.png') repeat scroll 0 0 lighten($black-1, 40.00);

  .card {
    background-color: $gray-1;
    box-shadow: 0 15px 30px darken($black-1, 13.33);
    max-width: 400px;
  }

  .footer-brand {
    position: absolute;
    right: 50px;
    bottom:30px;
  }
}
