.card {
  border-radius: 6px 6px 0 0;
  border: 1px solid rgba(darken(saturate($gray-1, 0.86), 37.65), 0.59);

  &.bg-gray {
    background-color: $gray-1;
  }

  legend {
    font-size: rem(14px);
    border-bottom-color: darken($gray-1, 20%);
  }
}

.card-header {

  &.bg-primary {
    background-color: $green-1;
    border-bottom-color: $green-1;
    padding: rem(8.5px) 1rem;
    color: white;
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  h4 {
    font-size: .8125rem;
    font-weight: bold;
    margin: 0;
  }
}

.nav-tabs>li.active:before {
  background-color: $green-1;
}
