
/* Datetime Picker */
.dropdown-menu {
  z-index: 10000;
}

.datetimepicker {
  .table.table-condensed > tbody > tr > td,
  .table.table-condensed > tbody > tr > th,
  .table.table-condensed > tfoot > tr > td,
  .table.table-condensed > tfoot > tr > th,
  .table.table-condensed > thead > tr > td,
  .table.table-condensed > thead > tr > th {
    padding: 5px;
  }
}


/* alerts */
.sweet-alert .pulseWarning,
.sweet-alert .pulseWarningIns {
  animation: none;
}

body.stop-scrolling {
  overflow: auto;
}

/* loading */
.loading {
  display: none;
}

.loading {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
  animation: background-fade 5s;
  background: white;

  .sk-rotating-plane {
    position: fixed;
    top: 40%;
    z-index: 1;
    left: 50%;
    background-color: $green-1;
  }
}

@keyframes background-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

//pagination
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  border-color: darken($green-1, 10%);
  background-color: darken($green-1, 10%);
}

.pagination>li>a,
.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  color: darken($green-1, 10%);
}


// google maps
.angular-google-map-container { height: calc(100vh - 210px) }

.marker-info {
  background-color: $green-1;
  border-color: 1px solid $green-1;
  color: white;
  border-radius: 4px;
  width: rem(250px);
  padding: 10px;
}

// treeview
ul.ivh-treeview {

  ul {
    list-style: none;
  }

  li.ivh-treeview-node-leaf .ivh-treeview-twistie-leaf {
    display: none;
  }

  .ivh-treeview-node {
    display: block;
  }

  .ivh-treeview-twistie-collapsed,
  .ivh-treeview-twistie-expanded,
  .ivh-treeview-node-label {
    cursor: pointer;
  }
}

.p-l-0 > ul.ivh-treeview {
  padding-left: 0;
}


.custom-timeline {
  background: white;
  border: 1px solid $black-1;
  padding-top: 20px;

  .timeline {

    &-line {
      border-bottom: 2px dashed $green-1;
      display: block;
    }

    &-events {
      overflow-x: auto;
      white-space: nowrap;
    }

    &-event {
      display: inline-block;
      position: relative;
      margin: 10px 30px 20px 30px;

      &:hover {
        color: inherit;
      }

      &:before {
        content: " ";
        display: block;
        width: 10px;
        height:10px;
        border-radius: 50%;
        background-color: $green-1;
        position: absolute;
        top: -10px;
        left: 50%;
      }

      &-selected {
        color: $green-1 !important;
      }
    }
  }
}


// color picker
.angular-color-picker {
  position: absolute;
  z-index:1000;
}
